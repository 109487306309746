import "./App.css";
import React from "react";
import { useState } from "react";

function App() {
  const [list, setList] = useState([]);
  const [item, setItem] = useState("");
  function handleOnChange(e) {
    e.preventDefault();
    setList([...list, item]);
    setItem("");
  }
  function deleteItem(index) {
    const newItems = [...list];
    newItems.splice(index, 1);
    setList(newItems);
  }
  return (
    <div>
      <h1>ToDo List</h1>
      <form onSubmit={handleOnChange}>
        <input
          type="text"
          value={item}
          onChange={(e) => setItem(e.target.value)}
        />
        <input type="submit" value="Add em" />
      </form>
      <ul>
        {list.map((item, index) => (
          <li key={index}>
            <button onClick={() => deleteItem(index)} />
            {item}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default App;
